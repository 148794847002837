import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import '@/assets/scss/styles.scss'
import './mixins/globalMethods.js';

Vue.config.productionTip = false
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
  mounted(){
    navigator.serviceWorker.controller.postMessage({
      type: 'SET_APP_ICON'
    })
  }
}).$mount('#app')

