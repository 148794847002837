<template>
  <v-app class="overflow-hidden" id="menuPages">

      <v-app-bar
          id="menuNavigation"
          color="secondary darken-3"
          elevation="0"
          dark
          absolute
          shrink-on-scroll
          fade-img-on-scroll
          extended
          height="300"
          scroll-target="#scrolling-menu"
          scroll-threshold="300"
          app
          :src=" this.venue.restaurantPicture && imageList.length === 0  ? this.venue.restaurantPicture : 'https://picsum.photos/1920/1080?random'"
      >

            <template v-slot:img="{ props }">
            
              <v-carousel v-if="imageList.length > 0" cycle :interval="5000"  hide-delimiter-background  :show-arrows="false" hide-delimiters v-bind="props" v-model="carouselElement">
                <v-carousel-item class="header-carousel-item"
                  v-for="(dataUrl, index) in imageDataUrls" 
                  :key="index">
                  <v-img  class="header-carousel-img" position="center center" :src="dataUrl"></v-img>
                </v-carousel-item>
              </v-carousel>
              <v-img v-else
                v-bind="props"
              ></v-img>
            </template>

            <v-sheet class="carousel">  
            </v-sheet>

            <v-sheet height="56" class="logo-container  d-flex justify-center align-center"> 
              <!-- <v-icon class="xxxxx-large">$baliLogoHorizontal</v-icon>  -->
            </v-sheet>
            <v-app-bar-nav-icon @click="goToQR"><v-icon>mdi-arrow-left</v-icon></v-app-bar-nav-icon>
            

            <v-sheet v-if="imageList.length > 0" height="20" class="custom-carousel-nav row">
              <v-container fluid class="py-0">
                <v-row class="d-flex flex-row flex-nowrap" no-gutters>
                  <v-col cols="auto">
                    <v-btn @click="carouselMoveBackward" class="carousel-btn" fab text small>
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="auto">
                    <v-btn @click="carouselMoveForward" class="carousel-btn" fab text small>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
            <v-app-bar-title class="text-uppercase display-1 pb-7 text-shadow">
              {{venue.restaurant}}
              <v-tooltip top color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="additionalInfo" text small fab v-bind="attrs" v-on="on">
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ venue.information }}</span>
              </v-tooltip>
              <v-row class="additionalInfo body-2">
                <v-col cols="12" class="py-2">
                  <v-divider class="divider-white" dark></v-divider>
                </v-col>
                <v-col cols="4">
                  <!-- <span class="font-weight-light"><v-icon class="mr-1">mdi-emoticon-happy-outline</v-icon> <span class="light--text">800</span></span> -->
                  <!-- {{ venue.information }} -->
                </v-col>
                <v-col cols="8" class="text-right">
                  <span class="font-weight-light"> {{venue.table}}</span>
                </v-col>
              </v-row>
              
            </v-app-bar-title>
      
            <v-spacer></v-spacer>
      
            <v-btn icon @click="showSearch()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
      
            <v-btn icon>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
      
            <template v-slot:extension>
              <v-sheet height="20" class="custom-carousel-controls row">
                <v-container class="py-0">
                  <v-row class="d-flex flex-row flex-nowrap" no-gutters>
                    
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="d-flex align-center justify-center flex-no-wrap">
                      <v-btn :class="index === carouselElement ? 'active carousel-btn':'carousel-btn'" text fab x-small @click="carouselElement = index" v-for="(image, index) in imageList" :key="index" >
                        <v-icon  small :color=" index === carouselElement ? 'primary' : 'white'" data-img="image">
                          {{ index === carouselElement ? 'mdi-circle-medium':'mdi-circle-small' }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    
                  </v-row>
                </v-container>
              </v-sheet>
              <v-tabs v-model="tab_selected" align-with-title show-arrows center-active>
                  <v-tab class="font-weight-light" v-for="(category, index) in categories" :key="index"  @click.stop="scrollToLink(index)">
                    {{category.name}}
                  </v-tab>
              </v-tabs>
            </template>
      </v-app-bar>
      <v-sheet
          id="scrolling-menu"
          class="overflow-y-auto"
          color="secondary darken-2"
          :max-height=" this.footer ? '(window.height - this.bottomNavigation)': window.height"
      >
          <v-overlay app :value="searchOverlay" opacity="0.75" class="search-overlay d-flex align-start">
<!-- LIST ALL ITEMS ON SEARCH ******************************************** -->

            <v-layout fill-height >
              <v-container class="pa-0" fluid>
                  <v-card class="overlay-card"  id="searchResults" :max-height="window.height">
                    <v-row>
                      <v-col cols="12" class="">
                      <!-- data iterator starts  -->
                          <v-data-iterator
                            :items="this.parsedItems"
                            :items-per-page.sync="itemsPerPage"
                            :page="page"
                            :hide-default-footer=true
                            :sort-by="sortBy.toLowerCase()"
                            :sort-desc="sortDesc"
                            @current-items="updateSearchResults"
                          >
                            <template v-slot:header>
                              <v-toolbar extended dark color="secondary darken-3" class="mb-0">
                                <v-btn text fab small @click="searchOverlay = !searchOverlay"><v-icon>mdi-arrow-left</v-icon></v-btn>
                                <v-app-bar-title class="font-weight-thin text-uppercase" style="font-size: 20px;line-height: 33px;">
                                  Buscar en {{venue.restaurant}}
                                </v-app-bar-title>
                          
                                <v-spacer></v-spacer>
                          
                                <v-btn icon @click="showSearch()">
                                  <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                          
                                <v-btn icon>
                                  <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                                <template  v-slot:extension>
                                  <v-container>
                                    <v-row no-gutters>
                                        <v-col cols="12" class="d-flex align-center">
                                          <v-text-field
                                          v-model="search"
                                          prepend-inner-icon="mdi-magnify"
                                          label="Buscar..."
                                          rounded
                                          class="search-field"
                                          dense
                                          solo
                                          hide-details
                                          ref="globalSearch"
                                          clearable
                                        ></v-text-field>
                                        <v-btn text fab small><v-icon>mdi-filter</v-icon></v-btn>
                                      </v-col>
                                    </v-row>
                                </v-container>
                                </template>
                              </v-toolbar>
                            </template>
                            <template v-slot:no-results>
                              <v-card class="d-flex align-center" :min-height="(window.height - 104)" :max-height="(window.height- 104)">
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" class="text-center">
                                      <v-icon class="auto-adjust auto-height">$nothingFoundIllustration</v-icon>
                                      <h3 class="font-weight-thin">No se encontraron resultados  </h3>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card>
                            </template>
                            <template v-slot:no-data>
                              <v-card class="d-flex align-center" :min-height="(window.height - 104)" :max-height="(window.height- 104)">
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" md="6" offset-md="3" class="text-center">
                                      <v-icon class="auto-adjust auto-height">$nothingFoundIllustration</v-icon>
                                      <h3 class="font-weight-thin">No se encontraron resultados </h3>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card>
                            </template>
                            <template v-slot:default="props">
                                  <v-card  v-if="search != null && search != '' && search.length > 2" class="card-overflow-scroll "  :min-height="(window.height - 104)" :max-height="(window.height - 104)">
                                    <v-container>
                                      <v-row no-gutters>
                                        <v-col class="result" v-for="(item, index) in props.items" v-bind:key="index" cols="12">
                                          <v-card elevation="1" flat class="py-0 category-card" :data-category="item.category" color="secondary darken-2">
                                            <v-card-title class="d-flex flex-column align-start" v-if="checkForNewCategory(index)" >
                                              <h2 class="category" v-html="item.category"></h2>
                                              <h5 v-html="item.categorySubtitle"></h5> 
                                            </v-card-title>
                                            <v-list color="secondary darken-2" class="product-list" three-line>
                                                  <v-list-item>
                                                    <v-list-item-content class="d-flex flex-start align-self-start py-1">
                                                      <v-list-item-title class="text-h6 mb-1" v-html="highlightText2(item.name)"></v-list-item-title>
                                                      <v-list-item-subtitle class="d-flex flex-row flex-wrap">
                                                        <span class="body-1 mb-2 font-italic pricingValue">
                                                          <span class="coin">{{globalSettings.coin.symbol}} </span>
                                                          <span class="price">{{splitDecimals(convertCentsToDollars(item.price))[0]}}</span>
                                                          <span class="cents">{{splitDecimals(convertCentsToDollars(item.price))[1]}}</span>
                                                        </span>
                                                        <v-spacer></v-spacer>
                                                        <div v-if="item.tagsIcon.length > 0">
                                                          <v-chip :small="$vuetify.breakpoint.smAndDown" outlined class="mr-1" v-for="tag in item.tagsIcon" :key="tag.id">
                                                            <v-icon>{{ tag.icon}}</v-icon>
                                                            <span v-html="highlightText2(tag.name)"></span>
                                                          </v-chip>
                                                        </div>
                                                      
                                                      </v-list-item-subtitle>
                                                      <v-list-item-subtitle class="body-2 pt-2" v-html="highlightText2(item.description)"></v-list-item-subtitle>
                                                      
                                                      <!-- <v-list-item-subtitle class="caption mt-2">
                                                        <v-chip x-small outlined v-for="tag in item.tags" v-bind:key="tag" v-html="highlightText2(tag)"></v-chip>
                                                      </v-list-item-subtitle> -->
                                                    </v-list-item-content>
                                                    <v-list-item-avatar class="my-1 align-self-center" :min-width="$vuetify.breakpoint.mdAndUp ? 300 : 130" :min-height="$vuetify.breakpoint.mdAndUp ? 200 : 100" rounded>
                                                      <v-img v-if="item.image" class="product-img" aspect-ratio="1.4" :min-width="$vuetify.breakpoint.mdAndUp ? 300 : 130" :min-height="$vuetify.breakpoint.mdAndUp ? 200 : 100" :lazy-src="placeholderImageUrl" :src="item.image" @click="openDialog(item)">
                                                        <template v-slot:placeholder>
                                                          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
                                                        </template>
                                                      </v-img>
                                                      <v-img v-else aspect-ratio="1.4" class="placeholder-img"  :min-width="$vuetify.breakpoint.mdAndUp ? 300 : 130" :min-height="$vuetify.breakpoint.mdAndUp ? 200 : 100" :src="placeholderImageUrl" @click="openDialog(item)"></v-img>
                                                    </v-list-item-avatar>
                                                  </v-list-item>
                                            </v-list>
                                          </v-card>
                                        </v-col>
                                      </v-row>                                  
                                    </v-container>

                                  </v-card>
                                  <v-card v-else :min-height="(window.height - 104)" :max-height="(window.height - 104)" class="d-flex align-center">
                                    <v-container>
                                      <v-row>
                                        <v-col cols="12" md="8" xl="4" offset-md="2" offset-xl="4" class="text-center flex-grow-1">
                                          <v-icon class=" auto-adjust auto-height">$searchMenuIllustration</v-icon>
                                          <h3 class="font-weight-thin"> Escribe para iniciar busqueda </h3>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-card>
                            </template>
                          </v-data-iterator>
<!-- data iterator ends  -->
                      </v-col>
                    </v-row>
                </v-card>
              </v-container>
            </v-layout>

          </v-overlay>

<!-- LIST ALL ITEMS ON MENU ******************************************** -->
          <v-container fluid fill-height>
            <v-main id="menuMain">
              <v-row no-gutters>
                <v-col cols="12">             
                    <div class="menu-section" v-for="(category, index) in categories" v-bind:key="index">                     
                      <v-card class="category-card" color="secondary darken-2" elevation="0" :id="'sec_'+index" :class="'card-'+category.name.replace(/\s+/g, '-').trim()"
                        v-intersect.quiet="{
                          handler: onIntersect,
                          options: {
                            threshold: [0.1, 0.33 , 0.66]
                          }
                        }"
                      >
                        <span class="linkTarget" :id="'link_'+index"></span>
                        <v-card-title class="d-flex flex-column align-start">
                          <h2 class="mr-2">{{category.name}}</h2>
                          <h5>{{category.subtitle}}</h5>
                        </v-card-title>
                          <v-list class="product-list" color="secondary darken-2" three-line >
            <!-- ITEMS if LIST VIEW ON *********************************** -->
                            <v-row v-if="category.listView">
                              <v-col cols="12" md="12" lg="6" xl="4" v-for="(item, index) in category.items" v-bind:key="index" :data-item-id="item.id">
                                  <v-list-item class="d-flex" three-line>
                                    <v-list-item-content class="py-0">
                                      <v-list-item-title>
                                        <span class="text-h6 mb-1" v-html="item.name"></span>
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        <span class="body-1 mb-2 font-italic pricingValue">
                                          <span class="coin">{{item.currencySymbol}} </span>
                                          <span class="price">{{splitDecimals(convertCentsToDollars(item.price))[0]}}</span>
                                          <span class="cents">{{splitDecimals(convertCentsToDollars(item.price))[1]}}</span>
                                        </span>
                                        <v-spacer></v-spacer>
                                        <div v-if="item.tagsIcon.length > 0">
                                          <v-chip :small="$vuetify.breakpoint.smAndDown" outlined class="mr-1" v-for="tag in item.tagsIcon" :key="tag.id">
                                            <v-icon>{{ tag.icon}}</v-icon>
                                            <span v-html="tag.name"></span>
                                          </v-chip>
                                        </div>
                                      </v-list-item-subtitle>
                                      <v-list-item-subtitle>
                                        <span v-if="item.description" cols="6" class="body-2  pt-2" v-html="item.description"></span>
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                  
                              </v-col>
                            </v-row>

            <!-- ITEMS if LIST VIEW OFF *********************************** -->

                            <v-row v-else>
                              <v-col cols="12" md="6" lg="4" xl="3" v-for="(item, index) in category.items" v-bind:key="index" :data-item-id="item.id">
                                <v-list-item class="d-flex" :class="$vuetify.breakpoint.mdAndUp ? 'flex-column-reverse align-start':'flex-row align-center'"> 
                                  <v-list-item-content class="d-flex flex-start align-self-start py-1">
                                    <v-list-item-title class="text-h6 mb-1" v-html="item.name"></v-list-item-title>
                                    <v-list-item-subtitle class="d-flex flex-row flex-wrap ">
                                      <span class="body-1 mb-2 font-italic pricingValue">
                                        <span class="coin">{{item.currencySymbol}} </span>
                                        <span class="price">{{splitDecimals(convertCentsToDollars(item.price))[0]}}</span>
                                        <span class="cents">{{splitDecimals(convertCentsToDollars(item.price))[1]}}</span>
                                      </span>
                                      <v-spacer></v-spacer>
                                      <div v-if="item.tagsIcon.length > 0">
                                        <v-chip :small="$vuetify.breakpoint.smAndDown" outlined class="mr-1" v-for="tag in item.tagsIcon" :key="tag.id">
                                          <v-icon>{{ tag.icon}}</v-icon>
                                          <span v-html="tag.name"></span>
                                        </v-chip>
                                      </div>
                                    </v-list-item-subtitle>
                                    <v-spacer></v-spacer>
                                    <v-list-item-subtitle class="body-2  pt-2" v-html="item.description"></v-list-item-subtitle>
                                  </v-list-item-content>
                                  <v-list-item-avatar class="my-1" :class="$vuetify.breakpoint.mdAndUp ? 'align-self-start':'align-self-center' "  :min-width="$vuetify.breakpoint.mdAndUp ? 400 : 130" :min-height="$vuetify.breakpoint.mdAndUp ? 200 : 100"  rounded>
                                    <v-img v-if="item.image" class="product-img" :min-width="$vuetify.breakpoint.mdAndUp ? 400 : 130"  :min-height="$vuetify.breakpoint.mdAndUp ? 200 : 100"  :lazy-src="placeholderImageUrl"  :src="item.image"  @click="openDialog(item)">
                                      <template v-slot:placeholder>
                                        <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
                                      </template>  
                                    </v-img> <!--TODO: should be the URL -->
                                    <v-img v-else aspect-ratio="1.4" class="placeholder-img" :min-width="$vuetify.breakpoint.mdAndUp ? 300 : 130" :min-height="$vuetify.breakpoint.mdAndUp ? 200 : 100" :src="placeholderImageUrl" @click="openDialog(item)"></v-img>
                                  </v-list-item-avatar>
                                </v-list-item>
                              </v-col>
                            </v-row>
                            
                          </v-list>

                      </v-card>
                    </div>                      
                </v-col>
              
              </v-row>
            
                <slot />
            </v-main>

          </v-container>


          <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
              <v-card  :max-height="window.height" :min-height="window.height" class="d-flex flex-column preview-card-overlay">
                  <v-card-text   class="flex-grow-1 d-flex flex-column align-center justify-center py-0">
                      <v-container class="py-0">
                        <v-row>
                          <v-col cols="12" md="10" offset-md="1" xl="8" offset-xl="2" class="flex-grow-1 d-flex flex-column align-center justify-center">
                            <v-img :max-height="(window.height - 205)" v-if="itemImageUrl" :src="itemImageUrl" contain class="pa-2" width="100%" @wheel.native.prevent="onWheel" @load="onImageLoaded" min-width="200" min-height="200">
                              <template v-slot:placeholder>
                                <v-row style="width:100%; height:100%" class="d-flex justify-center text-center align-center flex-grow-1">
                                  <v-col cols="12">
                                    <v-progress-circular
                                      indeterminate
                                      size="64"
                                      color="primary"
                                    ></v-progress-circular>
                                  </v-col>
                                </v-row>
                              </template>
                            </v-img>
                            <v-icon style="opacity: 0.2" class="xxxxxx-large light--text auto-adjust" v-else>$baliShield</v-icon>
                            <!-- <v-img v-else   contain class="pa-2" :max-height="(window.height - 205)"  min-width="80%" min-height="200" :src="placeholderImageUrl"></v-img> -->

                        </v-col>
                      </v-row>
                    </v-container>
                </v-card-text>
              
                <v-card-actions class="secondary darken-4 py-8 d-flex justify-start flex-column">
                  <v-row>
                    <v-col cols="12" class="d-flex flex-row align-center">
                      <h1 class="display-1">{{itemName}}</h1>
                      <v-spacer></v-spacer>
                      <v-btn fab @click="closeDialog">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <p>{{itemDescription}}</p>
                    </v-col>

                  </v-row>

                </v-card-actions>
              </v-card>
          </v-dialog>
      </v-sheet>

      <v-speed-dial
              v-if="this.footer"
              v-model="cart"
              bottom
              right
              absolute
              app
            >
              <template v-slot:activator>
                <v-btn
                  id="waiter"
                  v-model="cart"
                  :color="cart ? 'primary  darken-1' : 'secondary darken-1'"
                  dark
                  fab
                  :class="waiter? 'requested':''"
                >
                  <v-icon v-if="cart">
                    mdi-close
                  </v-icon>
                  <v-icon v-else>
                    $cartIcon
                  </v-icon>
                </v-btn>
              </template>
      </v-speed-dial>
      <v-speed-dial
            v-if="this.footer"
              v-model="waiter"
              bottom
              left
              absolute
              app
            >
              <template v-slot:activator>
                <v-btn
                  id="waiter"
                  v-model="waiter"
                  :color="waiter ? 'primary  darken-1' : 'secondary darken-1'"
                  dark
                  fab
                  :class="waiter? 'requested':''"
                >
                  <v-icon v-if="waiter">
                    mdi-close
                  </v-icon>
                  <v-icon v-else>
                    $waiterIcon
                  </v-icon>
                </v-btn>
              </template>
      </v-speed-dial>

      <v-bottom-navigation
          v-if="this.footer"
          :value="mainNavValue"
          color="primary"
          background-color="secondary darken-3"
          app
          absolute
          style="position: relative;"
      >
          <v-btn>
            <span>Ordenar</span>
            <v-icon>$orderIcon</v-icon>
          </v-btn>
      
          <v-btn>
            <span>Explorar</span>
            <v-icon>$restaurantIcon</v-icon>
          </v-btn>
      
          <v-btn>
            <span>Cuenta</span>
            <v-icon>mdi-account-outline</v-icon>
          </v-btn>
      </v-bottom-navigation> 
      
  </v-app>
</template>
<script>
import placeholder from '@/assets/images/placeholder.svg';

export default {
  name: 'MenuPages',
  data: () => ({
      footer: false,
      navigatingToTab: false,
      tab_selected: null,
      searchOverlay: false,
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: -1,
      sortBy: "categoryOrder",
      itemsPerPageArray: [1, 2],

      searchResults: [],
      matchAll: false,
      search: null,
      searchToolHeight:80,
      matchesTimer: null,

      colors: ['#E30613', '#FDC300', '#EF7D00'],

      itemImageUrl: null,
      itemDescription: null,
      itemName: null,
      dialog: false,
      zoomLevel: 1,
      isImageLoaded: false,

      placeholderImageUrl: placeholder,
      carouselElement: null,
      imageList: [
        // 'https://picsum.photos/1920/1080?random=1',
        // 'https://picsum.photos/1920/1080?random=2',
        // 'https://picsum.photos/1920/1080?random=3',
        // 'https://picsum.photos/1920/1080?random=4',
        // 'https://picsum.photos/1920/1080?random=5',
        // 'https://picsum.photos/1920/1080?random=6',
        // 'https://picsum.photos/1920/1080?random=7',
        // 'https://picsum.photos/1920/1080?random=8',
        // 'https://picsum.photos/1920/1080?random=9',
      ],
      preloadedImages: [],
      imageDataUrls: [],

      waiter: false,
      cart: false,
      cartProcess: false,

      mainNavValue: 1,
      window: {
          width: 0,
          height: 0
      },
      // categories:[
      //         {id:'01', title:'Entradas' ,items:[
      //           {id:'110', name: 'Burrito Vegetariano',         price: '5,900.00',  tags:['Vegetariano', 'Vegano'],             description:'Un burrito vegetariano con una deliciosa mezcla de arroz, frijoles, verduras y especias.'},
      //           {id:'111', name: 'Entrada de Cordero',          price: '2,400.00',  tags:['Cordero','Mediterraneo' ],           description:'Una deliciosa entrada de cordero cocido a la perfección con hierbas y especias.'},
      //           {id:'112', name: 'Canastas Gourmet',            price: '3,600.00',  tags:['Ganzo','Pate' ],                     description:'Una selección de canastas gourmet llenas de productos de alta calidad y deliciosos aperitivos.'},
      //           {id:'113', name: 'Ensalada Venecia',            price: '4,400.00',  tags:['Italiano','Internacional', 'Light' ],description:'Una ensalada fresca y deliciosa con lechuga, tomate, cebolla, queso y aderezos.'},
      //           {id:'114', name: 'Rollitos de Jamon Serrano',   price: '5,900.00',  tags:['Español','Internacional' ],          description:'Unos rollitos de jamón serrano con una deliciosa mezcla de jamón serrano, queso y hierbas.'},
      //           {id:'115', name: 'Pastelitos de Zanahoria',     price: '1,300.00',  tags:['Vegetariano','Yogurt Harissa' ],     description:'Unos pastelitos de zanahoria con una deliciosa mezcla de zanahorias ralladas, harina, huevos y especias.'},
      //           {id:'116', name: 'Pastel de Desayuno Salado',   price: '2,100.00',  tags:['Vegetariano', ],                     description:'Un pastel de desayuno salado con una deliciosa mezcla de queso, huevos, verduras y especias.'},
      //           {id:'117', name: 'Ensalada de Verano',          price: '3,900.00',  tags:['Light', ],                           description:'Una ensalada fresca y deliciosa con una mezcla de vegetales de temporada y aderezos.'},
      //           {id:'118', name: 'Canastas sorpresa',           price: '4,450.00',  tags:['Carne','Pollo', 'Cerdo' ],           description:'Una selección de canastas sorpresa con una variedad de productos deliciosos y de alta calidad.'},
      //           {id:'119', name: 'Ensalada de Papa',            price: '3,900.00',  tags:['Vegetariano', 'Light'],              description:'Una ensalada de papa con una deliciosa mezcla de papas cocidas, cebolla, mayonesa y hierbas.'},
      //         ]},
      //         {id:'02', title:'Platos Fuertes', items:[
      //           {id:'211', name: 'Pulpo en salsa mediterranea',               price: '5,900.00',  tags:['Pulpo', 'salsa mediterránea', 'mariscos'],   description:'El pulpo en salsa es un plato delicioso, ideal para aperitivos.'},
      //           {id:'212', name: 'Pastel de Pollo ',                          price: '2,400.00',  tags:['Pastel', 'pollo', 'masa', 'relleno'],   description:'El pastel de pollo, o polvorosa de pollo, es un pastel de pollo venezolano dulce y salado.'},
      //           {id:'213', name: 'Salmon al Horno',                           price: '3,600.00',  tags:['Salmón', 'horno', 'pescado'],   description:'El salmón al horno es una receta muy equilibrada y sana, apta para toda la familia. '},
      //           {id:'214', name: 'Mariscos Mediterraneos',                    price: '4,400.00',  tags:['Mariscos', 'Mediterráneos', 'mariscos frescos'],   description:'Sabrosos y afrodisíacos… Los mariscos se han alzado como protagonistas destacados de la gastronomía española formando platos de auténtico lujo.'},
      //           {id:'215', name: 'Lomo de Cordero Relleno',                   price: '5,900.00',  tags:['Cordero', 'relleno', 'cordero'],   description:'Corte el lomo de cordero, en forma transversal, relleno de castañas y rebozuelos '},
      //           {id:'216', name: 'Lomo de Res en salsa de esparragos',        price: '1,300.00',  tags:['Res', 'salsa de esparragos', 'carne'],   description:'Lomo de res en salsa oporto y espárragos al romero al termino que mas te guste.'},
      //           {id:'217', name: 'Salmón empapelado con pesto de espinaca',   price: '2,100.00',  tags:['Salmón', 'empapelado', 'pesto de espinaca', 'pescado'],   description:'Esta deliciosa receta de salmón empapelado cubierto con pesto de Espinaca Eva®, un poco de albahaca y semillas de girasol'},
      //           {id:'218', name: 'Albóndigas con salsa de champiñones',       price: '3,900.00',  tags:['Albóndigas', 'salsa de champiñones', 'carne'],   description:'Hechas con carne picada de ternera o de cerdo para un jugoso resultado,  bañadas de salsa de champiñones'},
      //           {id:'219', name: 'Salteado de brócoli y carne',               price: '4,450.00',  tags:['Brócoli', 'carne', 'salteado', 'vegetales'],   description:'Con toques de sillao y kion, este salteado de carne con brócoli con un delicioso sabor oriental.'},
      //           {id:'220', name: 'Pavo al Pastor',                            price: '3,900.00',  tags:['Pavo', 'pastor', 'pavo'],   description:'El pavo al pastor es una receta tradicional de la gastronomía mexicana muy consumida en la temporada navideña.'},
      //         ]},
      //         {id:'03', title:'Ensaladas ',items:[
      //           {id:'311', name: 'Ensalada Cesar',                    price: '5,900.00',  tags:['ensalada', 'lechuga', 'pollo', 'queso rallado', 'aderezo cesar'],  description:'Ensalada de lechuga romana y croûtons (trozos de pan tostado) con jugo de limón, aceite de oliva, huevo, salsa Worcestershire, anchoas, ajo, mostaza de Dijon, queso parmesano y pimienta negra..'},
      //           {id:'312', name: 'Ensalada Waldorf',                  price: '2,400.00',  tags:['ensalada', 'lechuga', 'manzana', 'nueces', 'uvas', 'aderezo de yogurt'],  description:'Manzanas ácidas troceadas, frutos secos, con mayonesa o algún aliño basado en la mayonesa.'},
      //           {id:'313', name: 'Ensalada Griega',                   price: '3,600.00',  tags:['ensalada', 'lechuga', 'tomate', 'cebolla', 'pepino', 'queso feta', 'aceitunas', 'aderezo de yogurt'],  description:'Elaborada de tomate, pepino, pimiento y cebolla roja, todo ello con sal, pimienta negra y orégano y aliñada con aceite de oliva.'},
      //           {id:'314', name: 'Ensalada cuscús',                   price: '4,400.00',  tags:['ensalada', 'cuscús', 'vegetales', 'aderezo de limón'],  description:'A base de sémola de trigo muy típica en la cocina bereber.'},
      //           {id:'315', name: 'Ensalada de Quinoa',                price: '5,900.00',  tags:['ensalada', 'quinoa', 'vegetales', 'aderezo de limón'],  description:'Fresca y colorida, muy saludable y llena de sabor.'},
      //           {id:'316', name: 'Ensalada de col con Pulled Pork',   price: '1,300.00',  tags:['ensalada', 'col', 'pulled pork', 'aderezo de mostaza'],  description:'Ensalada de col o coleslaw americana con una porcion de Pulled Pork, un poco mas intenso  que tu ensalada normal para los amantes de la barbacoa.'},
      //           {id:'317', name: 'Ensalada del Chef',                 price: '2,100.00',  tags:['ensalada', 'vegetales', 'frutas', 'proteínas', 'aderezo del chef'],  description:'Mezcla de frescas lechugas, tomate, pollo  a la plancha con el aderezo especial del chef.'},
      //           {id:'318', name: 'Ensalada de Verano ',                price: '3,900.00',  tags:['ensalada', 'vegetales', 'frutas', 'aderezo de hierbas'],  description:'Liviana y Fresca, con tomate, sandía y queso feta con aceite de cúrcuma.'},
      //           {id:'319', name: 'Ensalada de  Garbanzos',            price: '4,450.00',  tags:['ensalada', 'garbanzos', 'vegetales', 'aderezo de limón'],  description:'Ensalada muy completa con el colorido que le aportan los vegetales y el sabor cítrico de una sencilla vinagreta que la complementa.'},
      //           {id:'320', name: 'Ensalada Original Negroni',         price: '3,900.00',  tags:['ensalada', 'vegetales', 'frutas', 'aderezo original negroni'],  description:'Mezclas de Vegetales y  lechugas internacionales con nuestro toque especial, un gusto para la vista y el paladar.'},
      //         ]},       
      //         {id:'04', title:'Bebidas',items:[
      //           {id:'411', name: 'Horchata',                            price: '5,900.00',  tags:['bebida tradicional', 'arroz', 'leche', 'canela', 'azúcar', 'refrescante', 'dulce'],   description:'Una bebida tradicional hecha a base de arroz, leche, canela y azúcar. Refrescante y dulce, perfecta para disfrutar en un día caliente.'},
      //           {id:'412', name: 'Limonada de sandía y menta',          price: '2,400.00',  tags:['limonada', 'sandía', 'menta', 'fresca', 'dulce', 'suave'],   description:'Una deliciosa mezcla de limonada fresca con el sabor dulce y suave de la sandía y el aroma fresco de la menta.'},
      //           {id:'413', name: 'Granizado de limón',                  price: '3,600.00',  tags:['postre', 'limón', 'fresco', 'hielo rallado', 'caluroso'],   description:'Un postre fresco y conocido, hecho con limón recién exprimido y hielo rallado. Una opción perfecta para los días calurosos.'},
      //           {id:'414', name: 'Granizado de café',                   price: '4,400.00',  tags:['café', 'frío', 'hielo rallado', 'alternativa refrescante'],   description:'Una deliciosa combinación de café frío y hielo rallado. Perfecto para aquellos que buscan una alternativa refrescante al café caliente.'},
      //           {id:'415', name: 'Limonada de piña',                    price: '5,900.00',  tags:['limonada', 'piña', 'tropical'],   description:'Una deliciosa mezcla de limonada fresca con el sabor tropical de la piña. Perfecto para disfrutar en un día caliente.'},
      //           {id:'416', name: 'Limonada de frambuesas',              price: '1,300.00',  tags:['limonada', 'frambuesas', 'dulce', 'afrutado'],   description:'Una deliciosa mezcla de limonada fresca con el sabor dulce y afrutado de las frambuesas.'},
      //           {id:'417', name: 'Té chai especiado de almendras',      price: '2,100.00',  tags:['té', 'chai', 'especiado', 'almendras'],   description:'Un té caliente con una mezcla de especias tradicionales del chai y el sabor delicioso de las almendras.'},
      //           {id:'418', name: ' ',    price: '3,900.00',  tags:['té verde', 'helado', 'estilo moruno'],   description:'Un té verde helado con una mezcla de especias tradicionales del norte de África, perfecto para disfrutar en un día caliente.'},
      //           {id:'419', name: 'Gaseosas',                            price: '4,450.00',  tags:['bebidas', 'gaseosas', 'sabores', 'marcas populares'],   description:'Una variedad de bebidas gaseosas con diferentes sabores y marcas populares para elegir.'},
      //           {id:'420', name: 'Naturales',                           price: '3,900.00',  tags:['bebidas naturales', 'jugos frescos', 'agua mineral', 'agua con gas'],   description:'Una variedad de bebidas naturales, como jugos frescos, agua mineral y agua con gas.'},
      //         ]},
      //         {id:'05', title:'Postres',items:[
      //           {id:'551',name:"Pastel de Chocolate",price:'1200.99',tags:["chocolate","pastel"],description:"Un delicioso pastel de chocolate hecho con polvo de cocoa y cubierto con una ganache de chocolate."},
      //           {id:'552',name:"Cheesecake de Fresa",price:'1500.99',tags:["fresa","cheesecake"],description:"Un cheesecake clásico hecho con fresas frescas y una masa de galletas Graham."},
      //           {id:'553',name:"Tarta de Arándanos",price:'1400.99',tags:["arándanos","tarta"],description:"Una tarta de arándanos casera con una masa crujiente y un relleno dulce y ácido."},
      //           {id:'554',name:"Compota de Manzana",price:'1100.99',tags:["manzana","compota"],description:"Una compota de manzana cálida y reconfortante con una cobertura de avena crujiente."},
      //           {id:'555',name:"Mousse de Chocolate",price:'900.99',tags:["chocolate","mousse"],description:"Un mousse de chocolate rico y cremoso hecho con chocolate oscuro y nata montada."},
      //           {id:'556',name:"Flan de Caramelo",price:'800.99',tags:["caramelo","flan"],description:"Un flan tradicional hecho con leche condensada y caramelo líquido."},
      //           {id:'557',name:"Pastel de Limón",price:'1000.99',tags:["limón","pastel"],description:"Un pastel de limón fresco y con una corteza de galleta."},
      //           {id:'558',name:"Tiramisú",price:'1300.99',tags:["café","tiramisú"],description:"Un postre italiano clásico hecho con capas de bizcocho empapado en café y queso mascarpone."},
      //           {id:'559',name:"Helado de Vainilla",price:'400.99',tags:["vainilla","helado"],description:"Un delicioso helado de vainilla hecho con leche fresca y vainilla natural."},
      //           {id:'5510',name:"Pastel de Zanahoria",price:'1100.99',tags:["zanahoria","pastel"],description:"Un pastel de zanahoria suave y húmedo con una cobertura de queso crema."}
      //         ]},
      //         {id:'06', title:'Cocteles',items:[
      //           {id:'611', name: 'Margarita',         price: '5,900.00',         tags:['tequila', 'triple sec', 'jugo de lima'],      description:'Tequila, triple seco y jugo de lima o limón.'},
      //           {id:'612', name: 'Mojito',          price: '2,400.00',           tags:['ron', 'menta', 'azúcar', 'lima', 'soda'],      description:'Ron, azúcar, menta, lima, agua con gas o gaseosa y hielo.'},
      //           {id:'613', name: 'Gintonic',            price: '3,600.00',       tags:['gin', 'tonic', 'hielo', 'lima'],      description:'Ginebra y tónica.'},
      //           {id:'614', name: 'Caipirinha',            price: '4,400.00',     tags:['cachaça', 'azúcar', 'lima'],      description:'Cachaza, lima o limón, azúcar y hielo.'},
      //           {id:'615', name: 'Manhattan',   price: '5,900.00',               tags:['whiskey', 'vermut', 'angostura'],      description:'Whisky y vermut rojo.'},
      //           {id:'616', name: 'Piña colada',     price: '1,300.00',           tags:['ron', 'piña', 'crema de coco', 'leche de coco'],      description:'Piña, crema de coco y ron.'},
      //           {id:'617', name: 'Daiquiri:',   price: '2,100.00',               tags:['ron', 'jugo de lima', 'azúcar'],      description:'Ron blanco y zumo de limón o lima.'},
      //           {id:'618', name: 'Cosmopolitan',          price: '3,900.00',     tags:['vodka', 'triple sec', 'cranberry', 'lima'],      description:'Vodka, triple seco, zumo de lima y zumo de arándanos.'},
      //           {id:'619', name: 'Martini',           price: '4,450.00',         tags:['gin', 'vermut'],      description:'Ginebra y vermut seco.'},
      //           {id:'620', name: 'Mai Tai',            price: '3,900.00',        tags:['ron', 'curacao', 'orgeat', 'jugo de lima', 'jugo de piña'],      description:'Ron añejo, ron blanco, triple seco, zumo de lima, amaretto, azúcar y hielo.'}
      //         ]},
      //         {id:'07', title:'Niños',items:[
      //           {id:"701", name:"Hamburguesa con papas fritas", price:"12,999.99", tags:["carne"], description:"Una deliciosa hamburguesa servida con papas fritas y ketchup."},
      //           {id:"702", name:"Pollo frito con arroz", price:"15,999.99", tags:["pollo","arroz"], description:"Pechugas de pollo frito servido con arroz y una ensalada."},
      //           {id:"703", name:"Pizza Margherita", price:"14,999.99", tags:["pizza","tomate"], description:"Una pizza Margherita clásica con salsa de tomate, queso mozzarella y hojas de albahaca."},
      //           {id:"704", name:"Spaghetti con salsa boloñesa", price:"11,999.99", tags:["pasta","boloñesa"], description:"Spaghetti cocido al dente con una deliciosa salsa boloñesa hecha con carne picada y tomates."},
      //           {id:"705", name:"Filete de pescado con puré de papas", price:"9,999.99", tags:["pescado","puré de papas"], description:"Un filete de pescado fresco servido con puré de papas y una ensalada."},
      //           {id:"706", name:"Hot dog con papas fritas", price:"8,999.99", tags:["hot dog"], description:"Un hot dog servido con papas fritas y mostaza."},
      //           {id:"707", name:"Hamburguesa vegetariana con papas fritas", price:"9,999.99", tags:["vegetariana","papas fritas"], description:"Una hamburguesa vegetariana hecha de lentejas y verduras, servida con papas fritas."},
      //           {id:"708", name:"Pollo al ajillo con arroz", price:"10,999.99", tags:["pollo","ajo"], description:"Pechugas de pollo cocidas en una deliciosa salsa de ajo, servido con arroz y vegetales."}
      //         ]},
      // ], 

      // venue:{ id:'001', 
      //   name:'Cafe Negroni', 
      //   imageUrl: 'https://picsum.photos/1920/1080?random', 
      //   schedule: '',
      //   rating: '4' 
      // },
      globalSettings:{
        coin:{ name:'colones', symbol:'CRC'}
      }

  }),
  watch: {
     search(newVal, oldVal){
      if(newVal !== oldVal){
        console.log(this.search);
      }
     },
  },
  async created() {
      await this.$store.dispatch('menu/getMenu', this.$route.params.id);

      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      this.preloadImages();

      console.log(this.getContrastingColor(this.colors));
  },
  destroyed() {
      window.removeEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener('popstate', this.handleBackButton);
  },
  mounted() {
    // Add an event listener for the back button
    window.addEventListener('popstate', this.handleBackButton);

  },
  computed:{
    venue() {
      return this.$store.getters['menu/getVenue'];
    },
    categories() {
      return this.$store.getters['menu/getCategories'];
    },
    parsedItems(){
       let output = [];
      //  console.log(this.categories.length);
      var arrayLength = this.categories.length;
      for (var c = 0; c < arrayLength; c++) {
          // console.log(this.categories[c].title);
          var itemLength = this.categories[c].items.length;
          var currentCategory = this.categories[c].name;
          var currentCategoryOrder = this.categories[c].id;
          var currentCategorySubtitle = this.categories[c].subtitle;  
          for (var i = 0; i < itemLength; i++) {
            const newObj = {...this.categories[c].items[i]}; // create a new object with the same data as the original
            newObj['category'] = currentCategory; // add the new entry
            newObj['categoryOrder'] = currentCategoryOrder;
            newObj['categorySubtitle'] = currentCategorySubtitle;
              // console.log('|----'+this.categories[c].items[i].name);
            output.push(newObj);
            console.log(i);
          }
      }

      console.log(this.search)
      /*search area: the search assignment was removed from the v-data-iterator and purely apply to the data directly*/
      if(this.search !== null){
        const searchWords = this.search.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' ').filter(word => word);
        console.log("looking for "+ searchWords);
        return output.filter(item => {
          const itemValues = Object.entries(item).reduce((acc, [, value]) => {
            if (value !== null) {
              if (Array.isArray(value)) {
                // Handle arrays (like tags and tagsIcon) specifically
                value.forEach(val => {
                  if (typeof val === 'object' && val !== null) {
                    acc += ' ' + Object.values(val).join(' ');
                  } else {
                    acc += ' ' + val;
                  }
                });
              } else {
                acc += ' ' + value;
              }
            }
            return acc;
          }, '').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

          console.log('searching within****');
          console.log(itemValues);
          console.log('searching within**** Ends ');
          console.log()
          return searchWords.every(word => itemValues.includes(word));

          // if(this.matchAll === true){
          //    return searchWords.every(word => itemValues.includes(word));

          // }else{
          //   return searchWords.some(word => itemValues.includes(word));
          // }
          
        });
      }
      return output;
    },

  },
  methods: {
    goToQR(){
      this.$router.push('/');
    },
    getContrastingColor(colors) {
      // Calculate the relative luminance of each color
      const luminances = colors.map(color => {
        const r = parseInt(color.substring(1, 3), 16);
        const g = parseInt(color.substring(3, 5), 16);
        const b = parseInt(color.substring(5, 7), 16);
        return 0.2126 * r + 0.7152 * g + 0.0722 * b;
      });
      
      // Find the color with the highest luminance value
      const maxLuminance = Math.max(...luminances);
      
      // Calculate a dark color that has a significantly different luminance value
      const darkColor = maxLuminance < 128 ? '#fff' : '#000';
      
      return darkColor;
    },
    wrapAccentWords(html) {
      const regex = /(\b[\wÀ-ÖØ-öø-ÿ]*[À-ÖØ-öø-ÿ]+[\wÀ-ÖØ-öø-ÿ]*\b)/gi;
      return html.replace(regex, (match) => {
        const stripped = match.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        if (match !== stripped) {
          return `<span class="accented" data-accented="${stripped}">${match}</span>`;
        } else {
          return match;
        }
      });
    },
    highlightText2(html) {
        if (this.search !== null && html !== null) {
          const searchWords = this.search.trim().toLowerCase().normalize('NFD').split(/\s+/);
          console.log(html);
          const regex = new RegExp(`(${searchWords.join('|')})`, 'giu');
          return html.normalize('NFD').replace(regex, `<span class="highlight">$1</span>`);
        } else {
          return html;
        }
    },
    updateSearchResults(results) {
      this.searchResults = results;
    },
    checkForNewCategory(itemIndex){
      var isNewCat = false;
      console.log(  this.searchResults[itemIndex] );
      console.log( itemIndex);
      // console.log( this.parsedItems[index-1].name);
      if(itemIndex > 0){
        if( this.searchResults[itemIndex].category !==  this.searchResults[itemIndex-1].category ){
          isNewCat = true;
        }
      }
      else{
        isNewCat = true;
      }
      return isNewCat;
    },  
    getItems(cats){
      const obj = JSON.parse(cats);
      return obj;
    },
    showSearch(){
      history.pushState(null, null, window.location.href);

      this.searchOverlay = !this.searchOverlay;
      if(this.searchOverlay){
        setTimeout(() => {
          this.$refs.globalSearch.focus();
        }, 1000);
      }
      
    },
    scrollToLink(index){
      this.navigatingToTab = true;
      // document.getElementById("menuNavigation").classList.add('v-app-bar--is-scrolled');
      if(index >= 1){
        console.log('removing extra')
        document.getElementById("menuMain").style.padding = "104px 0px 56px";
        // document.getElementById("menuMain").classList.add('shrink');
      }
      var indexStr = index.toString();
      console.log(index);
      console.log(document.getElementById("link_"+indexStr));
      setTimeout(() => {
        document.getElementById("link_"+indexStr).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
      }, 250);

      setTimeout(() => {
        //Need to avoid intersectListener from interacting whilst doing a regular scroll. use NavigatingToTab boolean to know when this is happening.
        this.navigatingToTab = false;
        console.log( 'done scrolling -----------------------------------------------: '+this.navigatingToTab);
      }, 1200);

      // this.scrollIntoView( "link_"+indexStr , {behavior: 'smooth', inline: 'nearest'});
      // this.$vuetify.goTo('#sec_'+indexStr , { container: this.$refs.menuScroller });
    },
    onIntersect(entries){
      console.log(entries[0].intersectionRatio);
      console.log(entries[0].target.id +' - '+ entries[0].target.classList[entries[0].target.classList.length - 1]);

      console.log("end intersect  -------------------------------------------- ");

      let intersecting_element = entries[0]

      if(intersecting_element.intersectionRatio >= 0.33 && intersecting_element.intersectionRatio < 0.5  && !this.navigatingToTab ){
        let id = intersecting_element.target.id;
        let index = Number(id.split("_")[1]);
        this.tab_selected = index;
        console.log("changing tabs on scroll intersect  -------------------------------------------- " + this.tab_selected);

      }

    }, 
    openDialog( item ){
      this.dialog = true;
      this.zoomLevel = 1, 
      this.itemImageUrl = item.image;
      this.itemDescription = item.description;
      this.itemName = item.name;

        // Add the dialog to the app's history
      history.pushState(null, null, window.location.href);
    },
    closeDialog() {
      this.dialog = false;
      this.itemImageUrl = null;
      if (document.referrer === window.location.href) {
        history.back();
      }
    },
    handleBackButton() {
      // If the dialog is open, hide it instead of going back in history
      if (this.dialog) {
        this.closeDialog();
      }else if(this.searchOverlay){
        this.searchOverlay = false;
      }
    },
    onImageLoaded() {
      this.isImageLoaded = true;
    },
    onWheel(e) {
      e.preventDefault();
      const delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));
      if (delta > 0) {
        this.zoomLevel += 0.1;
      } else if (delta < 0 && this.zoomLevel > 0.2) {
        this.zoomLevel -= 0.1;
      }
    },
    preloadImages() {
      this.imageList.forEach(url => {
        const img = new Image();
        img.crossOrigin = 'Anonymous'; // Add this line
        img.onload = () => {
          const dataUrl = this.convertToDataURL(img);
          this.imageDataUrls.push(dataUrl);
        };
        img.src = url;
      });
    },
    convertToDataURL(image) {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0);
      return canvas.toDataURL();
    },
    carouselMoveForward() {
      if (this.carouselElement < this.imageList.length ) { // Adjust the condition based on the number of carousel items
        this.carouselElement++;
      }else{
        this.carouselElement = 0;
      }
    },
    carouselMoveBackward() {
      if (this.carouselElement > 0) {
        this.carouselElement--;
      }
    },

  },
}
</script>
<style lang="scss">

.custom-fade-transition-enter-active, .custom-fade-transition-leave-active {
  transition: opacity 0.5s;
}
.custom-fade-transition-enter, .custom-fade-transition-leave-to {
  opacity: 0;
}


/* This is for documentation purposes and will not be needed in your application */
#menuMain.shrink{
  padding: 104px 0px 56px;
}
#menuPages .v-speed-dial {
  position: absolute;
  z-index: 10;
  bottom: 35px;
}

#menuPages .v-btn--floating {
  position: relative;
}
#searchResults{
  .category{

  }
}
.preload-images {
  display: none;
}
.header-carousel-item{

  .header-carousel-img{
    min-height: 350px;
    max-height: 500px;
  }
}

</style>