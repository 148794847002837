<template>
    <v-container fluid>
      <v-data-iterator
        :items="items"
        :items-per-page.sync="itemsPerPage"
        :page="page"
        :search="search"
        :sort-by="sortBy.toLowerCase()"
        :sort-desc="sortDesc"
      >
        <template v-slot:header>
          <v-toolbar dark color="blue darken-3" class="mb-1">
            <v-text-field
              v-model="search"
              clearable
              flat
              solo-inverted
              hide-details
              prepend-inner-icon="mdi-search"
              label="Search"
            ></v-text-field>
            <template v-if="$vuetify.breakpoint.mdAndUp">
              <v-spacer></v-spacer>
              <v-select
                v-model="sortBy"
                flat
                solo-inverted
                hide-details
                :items="keys"
                prepend-inner-icon="mdi-search"
                label="Sort by"
              ></v-select>
              <v-spacer></v-spacer>
              <v-btn-toggle v-model="sortDesc" mandatory>
                <v-btn large depressed color="blue" :value="false">
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn large depressed color="blue" :value="true">
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </v-btn-toggle>
            </template>
          </v-toolbar>
        </template>
  
        <template v-slot:default="props">
          <v-row >
            <v-col v-for="(item, index) in props.items" v-bind:key="index" cols="12">
              <v-card>
                <v-card-title class="subheading font-weight-bold">{{ item.name }}</v-card-title>
                <v-list dense>
                  <v-list-item v-for="(key, index) in filteredKeys" :key="index">
                    <v-list-item-content :class="{ 'blue--text': sortBy === key }">{{ key }}:</v-list-item-content>
                    <v-list-item-content
                      class="align-end"
                      :class="{ 'blue--text': sortBy === key }"
                    >{{ item[key.toLowerCase()] }}</v-list-item-content>
                    <v-list-item-action>
                        {{item.description}}
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
  
      
      </v-data-iterator>
    </v-container>
  </template>
  <script>
  export default {
    name: "HelloWorld",
    data: () => ({
      itemsPerPageArray: [1, 2],
      search: "",
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 5,
      sortBy: "name",
      keys: ["Name", "Calories", "Fat"],
      items: [
        {
          name: "Yogurt",
          calories: 159,
          fat: 6.0,
          description: "Ut eiusmod ea fugiat ipsum labore ipsum minim Lorem consectetur sint.",
          category: ["bebida","comida","sabroso"]
        },
        {
          name: "Ice cream sandwich",
          calories: 237,
          fat: 9.0,
          description: "Ad ut aliqua nostrud deserunt do reprehenderit sint culpa nulla adipisicing ea fugiat anim quis.",
          category: "postre"
        },
        {
          name: "Donuts",
          calories: 262,
          fat: 16.0,
          description: "Velit ex incididunt dolore tempor do sit nostrud.",
          category: "postre"
        },
        {
          name: "Cups",
          calories: 305,
          fat: 3.7,
          description: "Tempor deserunt dolore quis pariatur ipsum veniam est qui consectetur amet reprehenderit.",
          category: "cup"
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          description: "Tempor deserunt dolore quis pariatur ipsum veniam est qui consectetur amet reprehenderit.",
          category: "cup postre"
        },
        {
          name: "Cupcaca",
          calories: 305,
          fat: 3.7,
          description: "Tempor deserunt dolore quis pariatur ipsum veniam est qui consectetur amet reprehenderit.",
          category: "caca"
        },
        {
          name: "Cupcuscus",
          calories: 305,
          fat: 3.7,
          description: "Tempor deserunt dolore quis pariatur ipsum veniam est qui consectetur amet reprehenderit.",
          category: "postre"
        },
        {
          name: "Cupcoke",
          calories: 305,
          fat: 3.7,
          description: "Tempor deserunt dolore quis pariatur ipsum veniam est qui consectetur amet reprehenderit.",
          category: "hamburguesa"
        },
        {
          name: "Cupshit",
          calories: 305,
          fat: 3.7,
          description: "Tempor deserunt dolore quis pariatur ipsum veniam est qui consectetur amet reprehenderit.",
          category: "hamburguesa"
        },
        {
          name: "Cupplan",
          calories: 305,
          fat: 3.7,
          description: "Tempor deserunt dolore quis pariatur ipsum veniam est qui consectetur amet reprehenderit.",
          category: "hamburguesa"
        },
        {
          name: "Cupcrap",
          calories: 305,
          fat: 3.7,
          description: "Tempor deserunt dolore quis pariatur ipsum veniam est qui consectetur amet reprehenderit.",
          category: "hamburguesa"
        },
      ],
    }),
    computed: {
      numberOfPages() {
        return Math.ceil(this.items.length / this.itemsPerPage);
      },
      filteredKeys() {
        return this.keys.filter((key) => key !== `Name`);
      },
    },
    methods: {
      nextPage() {
        if (this.page + 1 <= this.numberOfPages) this.page += 1;
      },
      formerPage() {
        if (this.page - 1 >= 1) this.page -= 1;
      },
      updateItemsPerPage(number) {
        this.itemsPerPage = number;
      },
    },
  };
  </script>