<template>
    <v-container fluid fill-height class="pa-0">
        <v-card color="secondary darken-4" v-if="!manualEntry" class="d-flex flex-column justify-space-between" style="width: 100%; overflow-y: auto; overflow-x: hidden;"  :min-height="window.height " :max-height="window.height">
            <v-card-title>
                <v-row class="d-flex justify-center align-center">
                    <v-col cols="12" class="text-center">
                        <v-icon class="xxxxxx-large auto-height">$baliLogoHorizontal</v-icon>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-spacer></v-spacer>

            <v-card-text>
                    <v-row>
                        <v-col cols="12" class="text-center">
                            <h3>Ingresa el Codigo QR del Restaurante</h3>
                        </v-col>
                        <v-col cols="12" md="6" offset-md="3">
                            <v-otp-input
                                v-model="manualOTP"
                                length="10"
                                type="text"
                                :disabled="loading"
                                @finish="onFinish"
                                class="uppercase"
                                ref="otpEntryField"
                        ></v-otp-input>
                        <v-btn v-if="failedAuto" @click="goMenu" outlined block>Validar y Ver Menu</v-btn>
                        <v-overlay absolute :value="loading">
                            <v-progress-circular
                              indeterminate
                              size="64"
                              color="primary"
                            ></v-progress-circular>
                        </v-overlay>
                        </v-col>
                    </v-row>
            </v-card-text>
            <v-spacer></v-spacer>

            <v-card-actions class="mb-4">
                <v-row>     
                    <v-col cols="12" md="6" offset-md="3">
                        Como funciona?
                        <p class="caption">En tu  mesa encontraras un codigo como el mostrado abajo, 
                            en tu app Bali puedes ingresar el codigo debajo del codigo QR 
                            para empezar a disfrutar de tu restaurante favorito.</p>
                    </v-col>
                    <v-col class="d-flex justify-center"  cols="12" md="6" offset-md="3">
                        <v-card  class="mb-5" light width="auto" max-width="350">
                            <v-card-title class="d-flex justify-center" > Nombre del Restaurante </v-card-title>
                            <v-card-text class="d-flex justify-center" > 
                                <v-img width="auto" max-width="180" contain src="@/assets/images/sampleQR.png"></v-img>
                            </v-card-text>
                            <v-card-title class="d-flex flex-column justify-center" >
                                <div class="flex-grow-1"> XXXXXXXXXX </div>
                                <div class="text-caption"> Codigo De Restaurante </div>
                            </v-card-title>
                            <v-card-title class="d-flex justify-center" >
                                <div> http://www.appBali.com </div> 
                                <div class="text-caption">Direccion web de Bali</div>
                            </v-card-title>
                        </v-card>
                        
                    </v-col>

                </v-row>
            </v-card-actions>
            <v-spacer></v-spacer>
        </v-card>
        <v-card v-else color="secondary darken-4" class="d-flex flex-column align-center justify-center " :min-height="window.height" :max-height="window.height" width="100%">
            <v-sheet height="200" style="z-index: 10;" class="logo-container "> 
                <v-row class="d-flex justify-center align-center">
                    <v-col cols="12" class="text-center">
                        <v-icon class="xxxxxx-large auto-height">$baliLogoHorizontal</v-icon>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <h3>Scannea el Codigo QR del Restaurante</h3>
                    </v-col>
                </v-row>
            </v-sheet>
            <QrcodeStream :camera="camera" :track="this.paintBoundingBox"  @decode="onDecode" @init="onInit" >
                <div v-show="showScanConfirmation"  class="scan-confirmation secondary">
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </div>
            </QrcodeStream>           
        </v-card>
        <v-footer color="secondary darken-4" class="opaque-element d-flex align-center justify-center" app fixed style="background-color: rgba(46, 46, 43, 0.66) !important;">
                <v-btn v-if="!manualEntry" color="secondary"  @click="manualEntrySwitch" >Escanea codigo QR</v-btn>
                <v-btn v-else color="secondary" @click="QrCodeSwitch">Ingresa codigo manual</v-btn>
        </v-footer> 
        <v-snackbar
            color="primary darken-2"
            style="z-index: 20"
            v-model="snackbar"            
            app
            multi-line
            centered
            elevation="20"
            :timeout="snackbarTimeout"
            >
                {{ error }}
                <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    OK
                </v-btn>
                </template>
        </v-snackbar>
    </v-container>    
</template>
<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
    components: {
        QrcodeStream,
    },
    data: () => ({
        manualEntry: false,
        manualOTP: null,
        failedAuto: false,
        loading: false,
        camera: 'auto',
        error: "",
        baliCode: null,
        showScanConfirmation: false,
        snackbar: false,
        snackbarTimeout: 4000,
        window: {
            width: 0,
            height: 0
        },

    }),
    mounted() {
        if (this.isMobile()) {
            this.manualEntry = !this.manualEntry;
        }
    },
    async created() {

        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        console.log( process.env.VUE_APP_API_URL)

    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    watch:{
        baliCode (newVal, oldVal){
            if(newVal === oldVal){
                this.error = "Still the same code!"
            }
       },
    },
    methods: {
        manualEntrySwitch(){
            this.manualEntry = true;
            this.$nextTick(() => {
                const firstOtpField = this.$refs.otpEntryField.$el.querySelector('input');
                firstOtpField.focus()
            });
        },
        QrCodeSwitch(){
            this.manualEntry = false;
        },
        goMenu(){
            this.loading = true;
            this.$router.push('/menu/' + this.manualOTP).catch(() => {
                    // TODO: show message cannot find QR
                this.error = "Codigo Incorrecto, Revisa tu codigo de mesa e intenta de nuevo.";
                this.$nextTick(() => {
                    const firstOtpField = this.$refs.otpEntryField.$el.querySelector('input');
                    firstOtpField.focus()
                });
                
                // const firstOtpField = this.$refs.otpEntryField.$el.querySelector('input');
                // firstOtpField.focus()
                // this.$refs.otpEntryField.focus();
                this.snackbar = true;
                this.loading = false;
            });
        },
        onFinish (rsp) {
            this.loading = true;
            this.$router.push('/menu/' + rsp).catch(() => {
                    // TODO: show message cannot find QR
                this.error = "Codigo Incorrecto";
                this.$nextTick(() => {
                    const firstOtpField = this.$refs.otpEntryField.$el.querySelector('input');
                    firstOtpField.focus()
                });
                this.failedAuto = true;
                // const firstOtpField = this.$refs.otpEntryField.$el.querySelector('input');
                // firstOtpField.focus()
                // this.$refs.otpEntryField.focus();
                this.snackbar = true;
                this.loading = false;
            });
        },
        async onDecode (decodedString) {
            this.baliCode = decodedString;
             //TODO: move this up 
            console.log(decodedString);
            const match = decodedString.match(/\/menu\/([a-zA-Z0-9]{1,10})$/);
            const code = match && match[1];

            if (code) {
                this.pause();
                await this.timeout(500);
                this.unpause();
               
                this.$router.push('/menu/' + code).catch(() => {
                    // TODO: show message cannot find QR
                    console.log('Unable to load this menu');
                    this.error = "No se puede leer este menu";
                    this.snackbar = true;

                });
                
            } else {
                console.log("QR Code is invalid");
                this.error = "Codigo QR con formato Invalido";
                this.snackbar = true;

            }
        },
        paintBoundingBox (detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
                const { boundingBox: { x, y, width, height } } = detectedCode

                ctx.lineWidth = 4
                ctx.strokeStyle = '#e30613'
                ctx.strokeRect(x, y, width, height)
            }
        },
        unpause () {
            this.camera = 'auto'
        },
        pause () {
            this.camera = 'off'
        },
        timeout (ms) {
            return new Promise(resolve => {
                window.setTimeout(resolve, ms)
            })
        },
        async onInit (promise) {
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                this.error = "ERROR: Bali necesita acceso a la camara"
                } else if (error.name === 'NotFoundError') {
                this.error = "ERROR: Dispositivo no cuenta con camara"
                } else if (error.name === 'NotSupportedError') {
                this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                this.error = "ERROR: Esta la camara siendo usada por otro proceso?"
                } else if (error.name === 'OverconstrainedError') {
                this.error = "ERROR: Camara disponible no es compatible"
                } else if (error.name === 'StreamApiNotSupportedError') {
                this.error = "ERROR: Stream API is not supported in this browser"
                } else if (error.name === 'InsecureContextError') {
                this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
                } else {
                this.error = `ERROR: Camera error (${error.name})`;
                }
                if(error){
                    this.snackbar = true;
                }
            } finally {
                this.showScanConfirmation = this.camera === 'off'
            }
        },
        isMobile() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
                )
            ) {
                return true;
            } else {
                return false;
            }
        },
    }
}
</script>
<style>
    .uppercase *{
        text-transform: uppercase;
    }
    body{
        overflow: hidden;
    }
</style>