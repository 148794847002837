<template>
  <div id="app" v-if="this.$route.meta.layout">
    <component :is="this.$route.meta.layout || 'div'">
      <router-view />
    </component>
  </div>
  <div id="app" v-else>
    <v-app>
      <v-main>
        <router-view/>
      </v-main>
    </v-app>
  </div>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
  #app{
    background-color: var(--v-secondary-base);;
  }
</style>
