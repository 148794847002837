import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Data from '../views/dataIterator.vue'
import Menu from '../views/Menu.vue'
import QRCode from '../views/qrcodescan.vue'

//layouts
import MenuPageLayout from '@/components/layouts/MenuPages.vue'
import SimplePageLayout from '@/components/layouts/SimplePage.vue'

import { hadValidMenuId } from '../auth/menuGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/qr'
  },
  {
    path: '/qr',
    name: 'QRCode', 
    component: QRCode, 
  },
  {
    path: '/menu/:id',
    name: 'Menu',
    component: Menu,
    meta: { layout: MenuPageLayout },
    beforeEnter: hadValidMenuId 
    // This is an interceptor for menus IDs
  },
  {
    path: '/data',
    name: 'Data',
    component: Data,
    meta: { layout: SimplePageLayout },
  },

  {
    path: '/playground',
    name: 'PlayHome', 
    component: Home, 
    meta: {layout: SimplePageLayout},
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
