import menu from "../store/modules/menu";

export const hadValidMenuId = async (to, from, next) => {
    const response = await menu.actions.getMenuInterceptor({}, to.params.id);

    if (response) {
        await setMenuInfo(response);
        await setCategories(response);
    }

    return response 
        ? next() // If it find the menu ID
        : next('/qr'); // If not
}

const setMenuInfo = async (data) => {
    menu.state.venue = {
        menuId: data.menuId,
        menuName: data.menuName, // not using it 
        restaurant: data.restaurant,
        information: data.information, // not using it 
        restaurantPicture: data.restaurantPicture,
        table: data.table
        // missing (schedule, rating)
    };
}

const setCategories = async (data) => {
    menu.state.categories = data.categories;
}