import Vue from 'vue';
import Vuex from 'vuex';

// IMPORT VUEX MODULES
import menu from './modules/menu'

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        menu: menu
    },
    state: {

    },
    mutations: {
        
    },
    actions: {

    },
    getters: {

    }
});

export default store;