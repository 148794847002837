<template>
  <v-container>
    <v-card> 
      <v-card-title>
        <h1>Features</h1>
      </v-card-title>
      <v-card-text>
        body goes here
      </v-card-text>
      <v-card-actions>
        <v-btn>Hello</v-btn>
        <v-btn>Bye</v-btn>
      </v-card-actions>
    </v-card>

    <v-row class="text-center">
      <v-col cols="12">
                        <v-card elevation="10" v-intersect="onIntersect">
                          <v-card-title class="primary--text text--lighten-5">Hi</v-card-title>
                          <v-card-title class="primary--text text--lighten-4">Hi</v-card-title>
                          <v-card-title class="primary--text text--lighten-3">Hi</v-card-title>
                          <v-card-title class="primary--text text--lighten-2">Hi</v-card-title>
                          <v-card-title class="primary--text text--lighten-1">Hi</v-card-title>
                          <v-card-title class="primary--text">Hi (500)</v-card-title>
                          <v-card-title class="primary--text text--darken-1">Hi</v-card-title>
                          <v-card-title class="primary--text text--darken-2">Hi</v-card-title>
                          <v-card-title class="primary--text text--darken-3">Hi</v-card-title>
                          <v-card-title class="primary--text text--darken-4">Hi</v-card-title>
                          <v-card-text>
                          </v-card-text>

                            <div>
                              <p class="font-weight-black">
                                Black text.
                              </p>
                              <p class="font-weight-bold">
                                Bold text.
                              </p>
                              <p class="font-weight-medium">
                                Medium weight text.
                              </p>
                              <p class="font-weight-regular">
                                Normal weight text.
                              </p>
                              <p class="font-weight-light">
                                Light weight text.
                              </p>
                              <p class="font-weight-thin">
                                Thin weight text.
                              </p>
                              <p class="font-italic">
                                Italic text.
                              </p>
                            </div>
                        </v-card>
                        <v-card  v-intersect="onIntersect" color="primary">
                            <v-card-title>Color Primary</v-card-title>
                            <v-card-text>
                                <v-btn color="primary lighten-5"> button</v-btn>
                                <v-btn color="primary lighten-4"> button</v-btn>
                                <v-btn color="primary lighten-3"> button</v-btn>
                                <v-btn color="primary lighten-2"> button</v-btn>
                                <v-btn color="primary lighten-1"> button</v-btn>
                                <v-btn color="primary"> button  500</v-btn>
                                <v-btn color="primary darken-1"> button</v-btn>
                                <v-btn color="primary darken-2"> button</v-btn>
                                <v-btn color="primary darken-3"> button</v-btn>
                                <v-btn color="primary darken-4"> button</v-btn>
                                <v-btn color="primary accent-1"> button</v-btn>
                                <v-btn color="primary accent-2"> button</v-btn>
                                <v-btn color="primary accent-3"> button</v-btn>
                                <v-btn color="primary accent-4"> button</v-btn>
                            </v-card-text>
                        </v-card>                                
                        <v-card  v-intersect="onIntersect" color="secondary">
                            <v-card-title>Color Secondary</v-card-title>
                            <v-card-text>
                                <v-btn color="secondary lighten-5"> button</v-btn>
                                <v-btn color="secondary lighten-4"> button</v-btn>
                                <v-btn color="secondary lighten-3"> button</v-btn>
                                <v-btn color="secondary lighten-2"> button</v-btn>
                                <v-btn color="secondary lighten-1"> button</v-btn>
                                <v-btn color="secondary"> button 500</v-btn>
                                <v-btn color="secondary darken-1"> button</v-btn>
                                <v-btn color="secondary darken-2"> button</v-btn>
                                <v-btn color="secondary darken-3"> button</v-btn>
                                <v-btn color="secondary darken-4"> button</v-btn>
                                <v-btn color="secondary accent-1"> button</v-btn>
                                <v-btn color="secondary accent-2"> button</v-btn>
                                <v-btn color="secondary accent-3"> button</v-btn>
                                <v-btn color="secondary accent-4"> button</v-btn>
                            </v-card-text>
                        </v-card>                                
                        <v-card  v-intersect="onIntersect" color="accent">
                            <v-card-title>Color Accent</v-card-title>
                            <v-card-text>
                                <v-btn color="accent lighten-5"> button</v-btn>
                                <v-btn color="accent lighten-4"> button</v-btn>
                                <v-btn color="accent lighten-3"> button</v-btn>
                                <v-btn color="accent lighten-2"> button</v-btn>
                                <v-btn color="accent lighten-1"> button</v-btn>
                                <v-btn color="accent"> button 500</v-btn>
                                <v-btn color="accent darken-1"> button</v-btn>
                                <v-btn color="accent darken-2"> button</v-btn>
                                <v-btn color="accent darken-3"> button</v-btn>
                                <v-btn color="accent darken-4"> button</v-btn>
                                <v-btn color="accent accent-1"> button</v-btn>
                                <v-btn color="accent accent-2"> button</v-btn>
                                <v-btn color="accent accent-3"> button</v-btn>
                                <v-btn color="accent accent-4"> button</v-btn>
                            </v-card-text>
                        </v-card> 
                        <v-card  v-intersect="onIntersect" color="bahaus_turquoise">
                            <v-card-title>Color bahaus_turquoise</v-card-title>
                            <v-card-text>
                                <v-btn color="bahaus_turquoise lighten-5" class="black--text"> button</v-btn>
                                <v-btn color="bahaus_turquoise lighten-4"> button</v-btn>
                                <v-btn color="bahaus_turquoise lighten-3"> button</v-btn>
                                <v-btn color="bahaus_turquoise lighten-2"> button</v-btn>
                                <v-btn color="bahaus_turquoise lighten-1"> button</v-btn>
                                <v-btn color="bahaus_turquoise"> button 500 </v-btn>
                                <v-btn color="bahaus_turquoise darken-1"> button</v-btn>
                                <v-btn color="bahaus_turquoise darken-2"> button</v-btn>
                                <v-btn color="bahaus_turquoise darken-3"> button</v-btn>
                                <v-btn color="bahaus_turquoise darken-4"> button</v-btn>
                                <v-btn color="bahaus_turquoise accent-1"> button</v-btn>
                                <v-btn color="bahaus_turquoise accent-2"> button</v-btn>
                                <v-btn color="bahaus_turquoise accent-3"> button</v-btn>
                                <v-btn color="bahaus_turquoise accent-4"> button</v-btn>
                            </v-card-text>
                        </v-card>
                         <v-card  v-intersect="onIntersect" color="bahaus_darkgray">
                            <v-card-title>Color bahaus_darkgray</v-card-title>
                            <v-card-text>
                                <v-btn color="bahaus_darkgray lighten-5" class="black--text"> button</v-btn>
                                <v-btn color="bahaus_darkgray lighten-4"> button</v-btn>
                                <v-btn color="bahaus_darkgray lighten-3"> button</v-btn>
                                <v-btn color="bahaus_darkgray lighten-2"> button</v-btn>
                                <v-btn color="bahaus_darkgray lighten-1"> button</v-btn>
                                <v-btn color="bahaus_darkgray"> button 500 </v-btn>
                                <v-btn color="bahaus_darkgray darken-1"> button</v-btn>
                                <v-btn color="bahaus_darkgray darken-2"> button</v-btn>
                                <v-btn color="bahaus_darkgray darken-3"> button</v-btn>
                                <v-btn color="bahaus_darkgray darken-4"> button</v-btn>
                                <v-btn color="bahaus_darkgray accent-1"> button</v-btn>
                                <v-btn color="bahaus_darkgray accent-2"> button</v-btn>
                                <v-btn color="bahaus_darkgray accent-3"> button</v-btn>
                                <v-btn color="bahaus_darkgray accent-4"> button</v-btn>
                            </v-card-text>
                        </v-card>
                        <v-card  v-intersect="onIntersect" color="bahaus_lightgray">
                            <v-card-title>Color bahaus_darkgray</v-card-title>
                            <v-card-text>
                                <v-btn color="bahaus_lightgray lighten-5" class="black--text"> button</v-btn>
                                <v-btn color="bahaus_lightgray lighten-4"> button</v-btn>
                                <v-btn color="bahaus_lightgray lighten-3"> button</v-btn>
                                <v-btn color="bahaus_lightgray lighten-2"> button</v-btn>
                                <v-btn color="bahaus_lightgray lighten-1"> button</v-btn>
                                <v-btn color="bahaus_lightgray"> button 500 </v-btn>
                                <v-btn color="bahaus_lightgray darken-1"> button</v-btn>
                                <v-btn color="bahaus_lightgray darken-2"> button</v-btn>
                                <v-btn color="bahaus_lightgray darken-3"> button</v-btn>
                                <v-btn color="bahaus_lightgray darken-4"> button</v-btn>
                                <v-btn color="bahaus_lightgray accent-1"> button</v-btn>
                                <v-btn color="bahaus_lightgray accent-2"> button</v-btn>
                                <v-btn color="bahaus_lightgray accent-3"> button</v-btn>
                                <v-btn color="bahaus_lightgray accent-4"> button</v-btn>
                            </v-card-text>
                        </v-card>
                        <v-card  v-intersect="onIntersect" color="error">
                            <v-card-title>Color Error</v-card-title>
                            <v-card-text>
                                <v-btn color="error lighten-5"> button</v-btn>
                                <v-btn color="error lighten-4"> button</v-btn>
                                <v-btn color="error lighten-3"> button</v-btn>
                                <v-btn color="error lighten-2"> button</v-btn>
                                <v-btn color="error lighten-1"> button</v-btn>
                                <v-btn color="error"> button 500 </v-btn>
                                <v-btn color="error darken-1"> button</v-btn>
                                <v-btn color="error darken-2"> button</v-btn>
                                <v-btn color="error darken-3"> button</v-btn>
                                <v-btn color="error darken-4"> button</v-btn>
                                <v-btn color="error accent-1"> button</v-btn>
                                <v-btn color="error accent-2"> button</v-btn>
                                <v-btn color="error accent-3"> button</v-btn>
                                <v-btn color="error accent-4"> button</v-btn>
                            </v-card-text>
                        </v-card> 
                        <v-card  v-intersect="onIntersect" color="info">
                            <v-card-title>Color info</v-card-title>
                            <v-card-text>
                                <v-btn color="info lighten-5"> button</v-btn>
                                <v-btn color="info lighten-4"> button</v-btn>
                                <v-btn color="info lighten-3"> button</v-btn>
                                <v-btn color="info lighten-2"> button</v-btn>
                                <v-btn color="info lighten-1"> button</v-btn>
                                <v-btn color="info"> button 500 </v-btn>
                                <v-btn color="info darken-1"> button</v-btn>
                                <v-btn color="info darken-2"> button</v-btn>
                                <v-btn color="info darken-3"> button</v-btn>
                                <v-btn color="info darken-4"> button</v-btn>
                                <v-btn color="info accent-1"> button</v-btn>
                                <v-btn color="info accent-2"> button</v-btn>
                                <v-btn color="info accent-3"> button</v-btn>
                                <v-btn color="info accent-4"> button</v-btn>
                            </v-card-text>
                        </v-card> 
                        <v-card  v-intersect="onIntersect" color="success">
                            <v-card-title>Color Success</v-card-title>
                            <v-card-text>
                                <v-btn color="success lighten-5"> button</v-btn>
                                <v-btn color="success lighten-4"> button</v-btn>
                                <v-btn color="success lighten-3"> button</v-btn>
                                <v-btn color="success lighten-2"> button</v-btn>
                                <v-btn color="success lighten-1"> button</v-btn>
                                <v-btn color="success"> button 500 </v-btn>
                                <v-btn color="success darken-1"> button</v-btn>
                                <v-btn color="success darken-2"> button</v-btn>
                                <v-btn color="success darken-3"> button</v-btn>
                                <v-btn color="success darken-4"> button</v-btn>
                                <v-btn color="success accent-1"> button</v-btn>
                                <v-btn color="success accent-2"> button</v-btn>
                                <v-btn color="success accent-3"> button</v-btn>
                                <v-btn color="success accent-4"> button</v-btn>
                            </v-card-text>
                        </v-card> 
                        <v-card  v-intersect="onIntersect" color="warning">
                            <v-card-title>Color Warning</v-card-title>
                            <v-card-text>
                                <v-btn color="warning lighten-5" class="black--text"> button</v-btn>
                                <v-btn color="warning lighten-4"> button</v-btn>
                                <v-btn color="warning lighten-3"> button</v-btn>
                                <v-btn color="warning lighten-2"> button</v-btn>
                                <v-btn color="warning lighten-1"> button</v-btn>
                                <v-btn color="warning"> button 500 </v-btn>
                                <v-btn color="warning darken-1"> button</v-btn>
                                <v-btn color="warning darken-2"> button</v-btn>
                                <v-btn color="warning darken-3"> button</v-btn>
                                <v-btn color="warning darken-4"> button</v-btn>
                                <v-btn color="warning accent-1"> button</v-btn>
                                <v-btn color="warning accent-2"> button</v-btn>
                                <v-btn color="warning accent-3"> button</v-btn>
                                <v-btn color="warning accent-4"> button</v-btn>
                            </v-card-text>
                        </v-card> 
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',
    
    data: () => ({
      categories:[
        {id:'01', title:'Entradas'},
        {id:'02', title:'Platos Fuertes'},
        {id:'03', title:'Ensaladas '},
        {id:'04', title:'Bebidas'},
        {id:'05', title:'Postres'},
        {id:'06', title:'Cocteles'},
        {id:'07', title:'Niños'},
      ], 
    }),
  }
  
</script>
<style lang="scss">

</style>