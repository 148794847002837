import Api from "@/services/api";

export default {
    namespaced: true,
    state: {
        venue: null,
        categories: [],
    },
    getters: {
        getVenue: state => { return state.venue },
        getCategories: state => { return state.categories }
    },
    mutations: {
        SET_CURRENT_MENU(state, venue) {
            state.venue = venue;
        },
        SET_CURRENT_CATEGORIES(state, categories) {
            state.categories = categories;
        },
    },
    actions: {
        saveVenue({ commit }, venue) {
            commit('SET_CURRENT_VENUE', venue);
        },
        saveCategories({ commit }, categories) {
            commit('SET_CURRENT_CATEGORIES', categories);
        },
        // eslint-disable-next-line no-empty-pattern
        async getMenuInterceptor({}, menuId) {
            try {
                const response = await Api().get(`/menus/${menuId}`);

                return response.data;
            } catch (e) {
                console.log(e);
                return undefined;
            }
        },
        async getMenu({ commit }, menuId) {
            try {
                const response = await Api().get(`/menus/${menuId}`);

                commit('SET_CURRENT_VENUE', "");
                return response.data;
            } catch (e) {
                console.log(e);
            }
        }
    }
}